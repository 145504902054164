import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import linkResolver from 'utils/linkResolver';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { TextLink, TextLinkExternal } from 'components/ui';

function Legal(props) {
  const { data } = props;
  const [date, setDate] = useState();
  const getYear = () => setDate(new Date().getFullYear());

  useEffect(() => {
    getYear();
  }, []);

  return (
    <Box>
      <Box mb={2}>
        <Typography variant="button" color="secondary">
          Copyright &copy; Living Space {date}. All rights reserved.
        </Typography>
      </Box>
      <Box
        component="ul"
        p={0}
        m={0}
        display="flex"
        justifyContent="space-between"
      >
        {data.map(({ title1, link }, key) => (
          <Box key={key} component="li" mb={1}>
            {link.__typename != 'PRISMIC__ExternalLink' && (
              <TextLink
                title={title1[0].text}
                link={`${link ? linkResolver(link._meta) : '/'}`}
              />
            )}
            {link.__typename == 'PRISMIC__ExternalLink' && (
              <TextLinkExternal title={title1[0].text} link={`${link.url}`} />
            )}
          </Box>
        ))}
      </Box>
    </Box>
  );
}

Legal.propTypes = {
  data: PropTypes.array.isRequired,
};

export default Legal;
