import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

function CustomerService(props) {
  const { data } = props;
  const doc = data.slice(0, 1).pop();
  if (!doc) return null;
  return (
    <Box>
      <Box mb={2}>
        <Typography
          component="p"
          variant="subtitle1"
          color="secondary"
          style={{ fontWeight: 'bold' }}
        >
          {doc.title[0].text}
        </Typography>
      </Box>
      <Box>
        <Typography
          component="p"
          variant="button"
          color="secondary"
          gutterBottom
        >
          {doc.text[0].text}
        </Typography>
      </Box>
      <Box>
        <Typography
          component="p"
          variant="button"
          color="secondary"
          gutterBottom
        >
          T - {doc.telephone[0].text}
        </Typography>
        <Typography
          component="p"
          variant="button"
          color="secondary"
          gutterBottom
        >
          E - {doc.email[0].text}
        </Typography>
      </Box>
    </Box>
  );
}

CustomerService.propTypes = {
  data: PropTypes.array.isRequired,
};

export default CustomerService;
