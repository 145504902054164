import React from 'react';
import { Box } from '@material-ui/core';
import { StaticQuery, graphql } from 'gatsby';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

function Spacer(props) {
  const { children } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <StaticQuery
      query={graphql`
        query GlobalSpacingQuery {
          prismic {
            allLayouts {
              edges {
                node {
                  spacer_value
                }
              }
            }
          }
        }
      `}
      render={data => (
        <Box
          pt={
            isMobile
              ? data.prismic.allLayouts.edges[0].node.spacer_value / 2
              : data.prismic.allLayouts.edges[0].node.spacer_value
          }
        >
          {children}
        </Box>
      )}
    />
  );
}

export default Spacer;
