import React, { useContext, useState } from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Link from 'gatsby-link';
import PropTypes from 'prop-types';
import posed from 'react-pose';
import { Context } from 'store/provider';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Typeograpy from '@material-ui/core/Typography';
import ClearIcon from '@material-ui/icons/Clear';
import Logo from 'images/LivingSpaceWhite.svg';
import linkResolver from 'utils/linkResolver';
import { withStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
  menuWrapper: {
    backgroundColor: theme.palette.primary.main,
    position: 'fixed',
    top: 0,
    height: '100vh',
    zIndex: 2,
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    width: '30vw',
    '&.closed': {
      left: '-100%',
    },
    '&.open': {
      left: 0,
    },
    [theme.breakpoints.down('lg')]: {
      width: '30vw',
    },
    [theme.breakpoints.down('md')]: {
      width: '100vw',
      justifyContent: 'flex-start',
    },
  },
  divider: {
    width: '80%',
    '& hr': {
      backgroundColor: theme.palette.text.white,
    },
  },
  navItem: {
    textAlign: 'center',
    marginBottom: '0.8rem',
  },
  navLink: {
    transition: 'transform 0.3s ease-in-out',
    transform: 'scale(1)',
    textDecoration: 'none',
    display: 'inline-block',
    '&:hover': {
      transform: 'scale(1.05)',
      '& .nav-underline': {
        width: '100%',
      },
    },
  },
  menuUnderline: {
    height: '1px',
    backgroundColor: theme.palette.text.white,
    width: '0%',
    margin: '0 auto',
    display: 'block',
    transition: 'width 0.3s ease-in-out',
  },
  close: {
    backgroundColor: 'transparent',
    padding: 0,
    margin: 0,
    border: 0,
    textTransform: 'uppercase',
    cursor: 'pointer',
    transform: 'scale(1)',
    transition: 'transform 0.3s ease-in-out',
    outline: 0,
    '&:hover': {
      transform: 'scale(1.1)',
    },
  },
  icon: {
    color: theme.palette.text.white,
  },
  logo: {
    width: '100px',
    marginTop: '2rem',
  },
  developmentsMenu: {
    position: 'absolute',
    display: 'none',
    right: '0px',
    top: '0px',
    background: theme.palette.secondary.main,
    transform: 'translateX(100%)',
    padding: '50px',
    height: '100%',
    width: '100%',
    opacity: 0,
    transition: 'opacity 0.3s ease-in-out',
    '&.open': {
      display: 'block',
      opacity: 1,
    },
  },
  list: {
    padding: 0,
    '& li': {
      listStyle: 'none',
    },
  },
  linkItem: {
    textDecoration: 'none',
    transition: 'color 0.3s ease-in-out',
    '&:hover': {
      color: theme.palette.background.border,
    },
  },
  underline: {
    textDecoration: 'underline',
  },
});

const Sidebar = posed.nav({
  open: { x: '0%', staggerChildren: 100 },
  closed: { x: '-100%' },
});

const SidebarExtended = posed.nav({
  open: { x: '0%', staggerChildren: 100 },
  closed: { x: '-100%' },
});

const NavItem = posed.li({
  open: { opacity: 1 },
  closed: { opacity: 0 },
});

const MainMenuQuery = graphql`
  query {
    prismic {
      allLayouts {
        edges {
          node {
            main_nav {
              bottom_margin_spacer
              title
              link {
                ... on PRISMIC_Standard_page {
                  _meta {
                    uid
                    type
                    id
                  }
                }
                ... on PRISMIC_Home_type {
                  _meta {
                    id
                    type
                    uid
                  }
                }
                ... on PRISMIC_Contact_page {
                  _meta {
                    id
                    type
                    uid
                  }
                }
                ... on PRISMIC_Article {
                  _meta {
                    id
                    type
                    uid
                  }
                }
                ... on PRISMIC_Homepage {
                  _meta {
                    id
                    type
                    uid
                  }
                }
                ... on PRISMIC_Basic_page {
                  _meta {
                    id
                    type
                    uid
                  }
                }
                ... on PRISMIC_Development {
                  _meta {
                    id
                    type
                    uid
                  }
                }
                ... on PRISMIC_Search_page {
                  _meta {
                    id
                    uid
                    type
                  }
                }
              }
            }
          }
        }
      }
      allDevelopments {
        edges {
          node {
            title
            menu_title
            address {
              town
              postcode
            }
            _meta {
              uid
              type
              id
            }
          }
        }
      }
      allProjects {
        edges {
          node {
            title
            address {
              town
              postcode
            }
            _meta {
              uid
              type
              id
            }
          }
        }
      }
    }
  }
`;

const NavWrapper = ({ isOpen, classes, themeColor }) => {
  const [state, dispatch] = useContext(Context);
  const [developmentsmenu, setDevelopmentsmenu] = useState(false);
  const [projectsmenu, setProjectsmenu] = useState(false);
  const menuToggle = () => {
    dispatch({ type: 'SET_MENU', payload: !state.menu });
    setDevelopmentsmenu(false);
    setProjectsmenu(false);
  };
  const developmentsMenuOpen = link => {
    if (link._meta.uid == 'our-projects') {
      setProjectsmenu(true);
    } else {
      setProjectsmenu(false);
    }
    if (link._meta.uid == 'developments') {
      setDevelopmentsmenu(true);
    } else {
      setDevelopmentsmenu(false);
    }
  };
  const hasComingSoon = data => {
    var ret = false;
    data.prismic.allDevelopments.edges.map(item => {
      if (item.node.development_status === false) {
        ret = true;
      }
    });
    return ret;
  };
  return (
    <StaticQuery
      query={`${MainMenuQuery}`}
      render={data => (
        <Sidebar
          className={`${classes.menuWrapper} ${isOpen ? 'open' : 'closed'}`}
          pose={isOpen ? 'open' : 'closed'}
        >
          <Box pt={2} pl={2}>
            <Box
              component="button"
              onClick={menuToggle}
              display="flex"
              alignItems="center"
              className={classes.close}
            >
              <Box mr={1}>
                <ClearIcon className={classes.icon} />
              </Box>
              <Typeograpy
                component="span"
                variant="subtitle2"
                color="secondary"
              >
                Close
              </Typeograpy>
            </Box>
          </Box>
          <Box display="flex" alignItems="center" flexDirection="column">
            <Box mt={[4, 4, 0]}>
              <Typeograpy
                component="p"
                variant="h5"
                color="secondary"
                align="center"
              >
                Discover
              </Typeograpy>
            </Box>
            <Box mb={[2, 2, 4]}>
              <Logo className={classes.logo} />
            </Box>
            <Box className={classes.divider} mb={4}>
              <Divider />
            </Box>
            <Box component="ul" p={0} m={0}>
              {data.prismic.allLayouts.edges[0].node.main_nav.map(
                ({ title, link, bottom_margin_spacer }, key) => (
                  <React.Fragment key={key}>
                    <NavItem className={classes.navItem}>
                      <Link
                        className={classes.navLink}
                        to={link ? linkResolver(link._meta) : ''}
                        onClick={() => menuToggle()}
                        onMouseEnter={() => developmentsMenuOpen(link)}
                      >
                        <Typeograpy
                          component="div"
                          variant="subtitle1"
                          color="secondary"
                        >
                          {title[0].text}
                        </Typeograpy>
                        <Box
                          component="span"
                          className={`${classes.menuUnderline} nav-underline`}
                        />
                      </Link>
                    </NavItem>
                    {bottom_margin_spacer && <Box py={bottom_margin_spacer} />}
                  </React.Fragment>
                )
              )}
            </Box>
          </Box>
          <Box />
          <Box
            className={`${classes.developmentsMenu} ${
              developmentsmenu ? 'open' : 'closed'
            }`}
          >
            <SidebarExtended pose={developmentsmenu ? 'open' : 'closed'}>
              <Box component="ul" className={classes.list}>
                {data.prismic.allDevelopments.edges.map((item, key) => {
                  if (item.node.development_status) {
                    return (
                      <Box
                        key={key}
                        component="li"
                        mb={3}
                        display="flex"
                        justifyContent="center"
                        flexDirection="column"
                        alignItems="center"
                      >
                        <Box>
                          <Typography
                            to={
                              item.node._meta
                                ? linkResolver(item.node._meta)
                                : ''
                            }
                            onClick={() => menuToggle()}
                            className={classes.linkItem}
                            component={Link}
                            variant="h6"
                            color="textPrimary"
                            align="center"
                          >
                            {item.node.menu_title
                              ? item.node.menu_title[0].text
                              : item.node.title[0].text}
                          </Typography>
                        </Box>
                        {item.node.address[0].town && (
                          <Box>
                            <Typography component="p" variant="caption">
                              {item.node.address[0].town[0].text}
                            </Typography>
                          </Box>
                        )}
                        {item.node.address[0].postcode && (
                          <Box>
                            <Typography component="p" variant="caption">
                              {item.node.address[0].postcode[0].text}
                            </Typography>
                          </Box>
                        )}
                      </Box>
                    );
                  }
                })}
              </Box>
              <Box component="ul" className={classes.list} mt={6}>
                {hasComingSoon(data) && (
                  <Box mb={2}>
                    <Typography
                      className={classes.linkItemUnderline}
                      variant="subtitle1"
                      color="textPrimary"
                      align="center"
                    >
                      COMING SOON
                    </Typography>
                  </Box>
                )}
                {data.prismic.allDevelopments.edges.map((item, key) => {
                  if (!item.node.development_status) {
                    return (
                      <Box
                        key={key}
                        component="li"
                        mb={3}
                        display="flex"
                        justifyContent="center"
                        flexDirection="column"
                        alignItems="center"
                      >
                        <Box>
                          <Typography
                            to={
                              item.node._meta
                                ? linkResolver(item.node._meta)
                                : ''
                            }
                            onClick={() => menuToggle()}
                            className={classes.linkItem}
                            component={Link}
                            variant="h6"
                            color="textPrimary"
                            align="center"
                          >
                            {item.node.menu_title
                              ? item.node.menu_title[0].text
                              : item.node.title[0].text}
                          </Typography>
                        </Box>
                        {item.node.address[0].town && (
                          <Box>
                            <Typography component="p" variant="caption">
                              {item.node.address[0].town[0].text}
                            </Typography>
                          </Box>
                        )}
                        {item.node.address[0].postcode && (
                          <Box>
                            <Typography component="p" variant="caption">
                              {item.node.address[0].postcode[0].text}
                            </Typography>
                          </Box>
                        )}
                      </Box>
                    );
                  }
                })}
              </Box>
            </SidebarExtended>
          </Box>

          <Box
            className={`${classes.developmentsMenu} ${
              projectsmenu ? 'open' : 'closed'
            }`}
          >
            <SidebarExtended pose={projectsmenu ? 'open' : 'closed'}>
              <Box component="ul" className={classes.list}>
                {data.prismic.allProjects.edges.map((item, key) => {
                  return (
                    <Box
                      key={key}
                      component="li"
                      mb={3}
                      display="flex"
                      justifyContent="center"
                      flexDirection="column"
                      alignItems="center"
                    >
                      <Box>
                        <Typography
                          to={
                            item.node._meta ? linkResolver(item.node._meta) : ''
                          }
                          onClick={() => menuToggle()}
                          className={classes.linkItem}
                          component={Link}
                          variant="h6"
                          color="textPrimary"
                          align="center"
                        >
                          {item.node.menu_title
                            ? item.node.menu_title[0].text
                            : item.node.title[0].text}
                        </Typography>
                      </Box>
                      {item.node.address[0].town && (
                        <Box>
                          <Typography component="p" variant="caption">
                            {item.node.address[0].town[0].text}
                          </Typography>
                        </Box>
                      )}
                    </Box>
                  );
                })}
              </Box>
            </SidebarExtended>
          </Box>
        </Sidebar>
      )}
    />
  );
};

NavWrapper.propTypes = {
  isOpen: PropTypes.bool,
  classes: PropTypes.object.isRequired,
  themeColor: PropTypes.string,
};

function Nav(props) {
  const { classes, themeColor } = props;
  const [state] = useContext(Context);
  return (
    <NavWrapper isOpen={state.menu} classes={classes} themeColor={themeColor} />
  );
}

Nav.propTypes = {
  classes: PropTypes.object.isRequired,
  themeColor: PropTypes.string,
};

export default withStyles(styles)(Nav);
