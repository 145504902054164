import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Logo from 'images/LivingSpaceLogoWhite.svg';
import { withStyles } from '@material-ui/styles';

const styles = theme => ({
  logo: {
    marginBottom: '20px',
    width: '100px',
    [theme.breakpoints.up('lg')]: {
      width: '100px',
    },
  },
});

function Awards(props) {
  const { data, classes } = props;
  return (
    <Box display="flex" flexDirection="column" alignItems="flex-start">
      <Box mb={2}>
        <Logo className={classes.logo} />
      </Box>
      <Box display={{ xs: 'none', sm: 'block' }}>
        <img className={classes.logo} src={data.url} alt={data.alt} />
      </Box>
    </Box>
  );
}

Awards.propTypes = {
  data: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Awards);
