import React, { useContext } from 'react';
import { StaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import Link from 'gatsby-link';
import { Context } from 'store/provider';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/styles';
import Logo from 'images/LivingSpaceWhite.svg';
import LogoGreen from 'images/LivingSpaceBlue.svg';
import MenuBurger from 'images/menuburger.svg';
import MenuBurgerReverse from 'images/menuburgerreverse.svg';

import linkResolver from 'utils/linkResolver';

import { AnchorLink } from 'gatsby-plugin-anchor-links';

const styles = theme => ({
  header: {
    width: '100%',
    zIndex: 2,
  },
  icon: {
    color: theme.palette.text.white,
    '&.blueIcon': {
      color: theme.palette.primary.main,
    },
  },
  menuBtn: {
    background: 'transparent',
    border: 0,
    padding: 0,
    cursor: 'pointer',
    transform: 'scale(1)',
    transition: 'transform 0.3s ease-in-out',
    outline: 0,
    '&:hover': {
      transform: 'scale(1.1)',
    },
  },
  navItem: {
    listStyle: 'none',
  },
  navLink: {
    textDecoration: 'none',
    transition: 'opacity 0.3s ease-in-out',
    '&:hover': {
      opacity: 0.6,
      '& .underline': {
        opacity: 1,
      },
    },
  },
  logo: {
    height: 'auto',
    width: '100px',
    [theme.breakpoints.up('md')]: {
      height: 'auto',
      width: '100px',
    },
  },
  underline: {
    display: 'block',
    height: '1px',
    width: '100%',
    background: theme.palette.text.white,
    opacity: 0,
    transition: 'opacity 0.3s ease-in-out',
  },
  ident: {
    height: '30px',
  },
  menuPinned: {
    display: 'none',
  },
});

const SecondaryMenuQuery = graphql`
  query {
    prismic {
      allLayouts {
        edges {
          node {
            secondary_nav {
              title
              link {
                ... on PRISMIC_Standard_page {
                  _meta {
                    uid
                    type
                    id
                  }
                }
                ... on PRISMIC_Home_type {
                  _meta {
                    id
                    type
                    uid
                  }
                }
                ... on PRISMIC_Contact_page {
                  _meta {
                    id
                    type
                    uid
                  }
                }
                ... on PRISMIC_Article {
                  _meta {
                    id
                    type
                    uid
                  }
                }
                ... on PRISMIC_Homepage {
                  _meta {
                    id
                    type
                    uid
                  }
                }
                ... on PRISMIC_Basic_page {
                  _meta {
                    id
                    type
                    uid
                  }
                }
                ... on PRISMIC_Development {
                  _meta {
                    id
                    type
                    uid
                  }
                }
                ... on PRISMIC_Search_page {
                  _meta {
                    id
                    type
                    uid
                  }
                }
              }
            }
            secondary_nav_b_2_b {
              title
              link {
                ... on PRISMIC_Standard_page {
                  _meta {
                    uid
                    type
                    id
                  }
                }
                ... on PRISMIC_Home_type {
                  _meta {
                    id
                    type
                    uid
                  }
                }
                ... on PRISMIC_Contact_page {
                  _meta {
                    id
                    type
                    uid
                  }
                }
                ... on PRISMIC_Article {
                  _meta {
                    id
                    type
                    uid
                  }
                }
                ... on PRISMIC_Homepage {
                  _meta {
                    id
                    type
                    uid
                  }
                }
                ... on PRISMIC_Basic_page {
                  _meta {
                    id
                    type
                    uid
                  }
                }
                ... on PRISMIC_Development {
                  _meta {
                    id
                    type
                    uid
                  }
                }
                ... on PRISMIC_Search_page {
                  _meta {
                    id
                    type
                    uid
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

function Header(props) {
  const {
    classes,
    themeColor,
    developmentPageMenu,
    homeTypePageMenu,
    hideMenu,
    headerMenuType,
  } = props;
  const [state, dispatch] = useContext(Context);

  const menuToggle = () => {
    dispatch({ type: 'SET_MENU', payload: !state.menu });
  };
  const groupMenuToggle = () => {
    dispatch({ type: 'SET_GROUP_MENU', payload: !state.menu });
  };

  return (
    <StaticQuery
      query={`${SecondaryMenuQuery}`}
      render={data => (
        <Box py={6} className={`${classes.header} header`}>
          <Container maxWidth={false}>
            <Grid container className="menu--block">
              <Grid item xs={2}>
                <Box
                  component="button"
                  display="flex"
                  alignItems="center"
                  onClick={menuToggle}
                  className={classes.menuBtn}
                >
                  <Box mr={1}>
                    {themeColor == 'white' ? (
                      <MenuBurgerReverse className={classes.icon} />
                    ) : (
                      <MenuBurger className={classes.icon} />
                    )}
                  </Box>
                  <Typography
                    component="span"
                    variant="subtitle2"
                    color={`${
                      themeColor == 'white' ? 'textPrimary' : 'secondary'
                    }`}
                  >
                    MENU
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={8}>
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  flexDirection="column"
                >
                  <Link to={'/'}>
                    {themeColor == 'white' ? (
                      <LogoGreen
                        className={`${classes.logo} logo green-logo`}
                      />
                    ) : (
                      <Logo className={`${classes.logo} logo white-logo`} />
                    )}
                  </Link>
                  <Box
                    component="ul"
                    display={{ xs: 'none', md: 'flex' }}
                    p={0}
                    my={3}
                  >
                    {!developmentPageMenu &&
                      !homeTypePageMenu &&
                      !hideMenu &&
                      !headerMenuType && (
                        <React.Fragment>
                          {data.prismic.allLayouts.edges[0].node.secondary_nav.map(
                            ({ title, link }, key) => (
                              <Box
                                key={key}
                                component="li"
                                px={2}
                                className={classes.navItem}
                              >
                                <Link
                                  className={classes.navLink}
                                  to={link ? linkResolver(link._meta) : ''}
                                >
                                  <Typography
                                    component="span"
                                    variant="subtitle1"
                                    color={`${
                                      themeColor == 'white'
                                        ? 'textPrimary'
                                        : 'secondary'
                                    }`}
                                  >
                                    {title[0].text}
                                  </Typography>
                                  <Box
                                    component="span"
                                    className={`${classes.underline} underline`}
                                  />
                                </Link>
                              </Box>
                            )
                          )}
                        </React.Fragment>
                      )}
                    {!developmentPageMenu &&
                      !homeTypePageMenu &&
                      !hideMenu &&
                      headerMenuType && (
                        <React.Fragment>
                          {data.prismic.allLayouts.edges[0].node.secondary_nav_b_2_b.map(
                            ({ title, link }, key) => (
                              <Box
                                key={key}
                                component="li"
                                px={2}
                                className={classes.navItem}
                              >
                                <Link
                                  className={classes.navLink}
                                  to={link ? linkResolver(link._meta) : ''}
                                >
                                  <Typography
                                    component="span"
                                    variant="subtitle1"
                                    color={`${
                                      themeColor == 'white'
                                        ? 'textPrimary'
                                        : 'secondary'
                                    }`}
                                  >
                                    {title[0].text}
                                  </Typography>
                                  <Box
                                    component="span"
                                    className={`${classes.underline} underline`}
                                  />
                                </Link>
                              </Box>
                            )
                          )}
                        </React.Fragment>
                      )}
                    {developmentPageMenu && (
                      <React.Fragment>
                        <Box component="li" px={2} className={classes.navItem}>
                          <AnchorLink
                            to={`${linkResolver(
                              developmentPageMenu
                            )}#availability`}
                            title="Availability"
                            className={`${classes.navLink} stripped`}
                            stripHash
                          >
                            <Typography
                              component="span"
                              variant="subtitle1"
                              color={`${
                                themeColor == 'white'
                                  ? 'textPrimary'
                                  : 'secondary'
                              }`}
                            >
                              Availability
                            </Typography>
                            <Box
                              component="span"
                              className={`${classes.underline} underline`}
                            />
                          </AnchorLink>
                        </Box>
                        <Box component="li" px={2} className={classes.navItem}>
                          <AnchorLink
                            to={`${linkResolver(developmentPageMenu)}#siteplan`}
                            title="Availability"
                            className={`${classes.navLink} stripped`}
                            stripHash
                          >
                            <Typography
                              component="span"
                              variant="subtitle1"
                              color={`${
                                themeColor == 'white'
                                  ? 'textPrimary'
                                  : 'secondary'
                              }`}
                            >
                              Site Plan
                            </Typography>
                            <Box
                              component="span"
                              className={`${classes.underline} underline`}
                            />
                          </AnchorLink>
                        </Box>
                        <Box component="li" px={2} className={classes.navItem}>
                          <AnchorLink
                            to={`${linkResolver(
                              developmentPageMenu
                            )}#housetypes`}
                            title="Availability"
                            className={`${classes.navLink} stripped`}
                            stripHash
                          >
                            <Typography
                              component="span"
                              variant="subtitle1"
                              color={`${
                                themeColor == 'white'
                                  ? 'textPrimary'
                                  : 'secondary'
                              }`}
                            >
                              House Types
                            </Typography>
                            <Box
                              component="span"
                              className={`${classes.underline} underline`}
                            />
                          </AnchorLink>
                        </Box>
                      </React.Fragment>
                    )}
                    {homeTypePageMenu && (
                      <React.Fragment>
                        <Box component="li" px={2} className={classes.navItem}>
                          <AnchorLink
                            to={`${linkResolver(homeTypePageMenu)}#price`}
                            title="Price"
                            className={`${classes.navLink} stripped`}
                            stripHash
                          >
                            <Typography
                              component="span"
                              variant="subtitle1"
                              color={`${
                                themeColor == 'white'
                                  ? 'textPrimary'
                                  : 'secondary'
                              }`}
                            >
                              Price
                            </Typography>
                            <Box
                              component="span"
                              className={`${classes.underline} underline`}
                            />
                          </AnchorLink>
                        </Box>
                        <Box component="li" px={2} className={classes.navItem}>
                          <AnchorLink
                            to={`${linkResolver(homeTypePageMenu)}#floorplans`}
                            title="Floorplans"
                            className={`${classes.navLink} stripped`}
                            stripHash
                          >
                            <Typography
                              component="span"
                              variant="subtitle1"
                              color={`${
                                themeColor == 'white'
                                  ? 'textPrimary'
                                  : 'secondary'
                              }`}
                            >
                              Floorplans
                            </Typography>
                            <Box
                              component="span"
                              className={`${classes.underline} underline`}
                            />
                          </AnchorLink>
                        </Box>
                        <Box component="li" px={2} className={classes.navItem}>
                          <AnchorLink
                            to={`${linkResolver(homeTypePageMenu)}#downloads`}
                            title="Downloads"
                            className={`${classes.navLink} stripped`}
                            stripHash
                          >
                            <Typography
                              component="span"
                              variant="subtitle1"
                              color={`${
                                themeColor == 'white'
                                  ? 'textPrimary'
                                  : 'secondary'
                              }`}
                            >
                              Downloads
                            </Typography>
                            <Box
                              component="span"
                              className={`${classes.underline} underline`}
                            />
                          </AnchorLink>
                        </Box>
                      </React.Fragment>
                    )}
                  </Box>
                </Box>
              </Grid>

              <Box
                component={Grid}
                item
                xs={2}
                className="header-block-right"
                display="flex"
                alignItems="flex-start"
                justifyContent="flex-end"
              >
                <Box
                  component="button"
                  display="flex"
                  alignItems="center"
                  onClick={groupMenuToggle}
                  className={classes.menuBtn}
                  aria-label="Menu toggle"
                >
                  <Typography
                    component="span"
                    variant="subtitle2"
                    color={`${
                      themeColor == 'white' ? 'textPrimary' : 'secondary'
                    }`}
                  >
                    GROUP
                  </Typography>
                  <Box ml={1}>
                    {themeColor == 'white' ? (
                      <MenuBurgerReverse className={classes.icon} />
                    ) : (
                      <MenuBurger className={classes.icon} />
                    )}
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid container className={`${classes.menuPinned} menu--pinned`}>
              <Box
                component={Grid}
                item
                xs={2}
                display="flex"
                alignItems="center"
              >
                <Box
                  component="button"
                  display="flex"
                  alignItems="center"
                  onClick={menuToggle}
                  className={classes.menuBtn}
                >
                  <Box mr={1}>
                    <MenuBurgerReverse className={classes.icon} />
                  </Box>
                </Box>
              </Box>
              <Grid item xs>
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  flexDirection="column"
                >
                  <Box
                    component="ul"
                    display={{ xs: 'none', md: 'flex' }}
                    p={0}
                    my={3}
                  >
                    {!developmentPageMenu &&
                      !homeTypePageMenu &&
                      !hideMenu &&
                      !headerMenuType && (
                        <React.Fragment>
                          {data.prismic.allLayouts.edges[0].node.secondary_nav.map(
                            ({ title, link }, key) => (
                              <Box
                                key={key}
                                component="li"
                                px={2}
                                className={classes.navItem}
                              >
                                <Link
                                  className={classes.navLink}
                                  to={link ? linkResolver(link._meta) : ''}
                                >
                                  <Typography
                                    component="span"
                                    variant="subtitle1"
                                    color="textPrimary"
                                  >
                                    {title[0].text}
                                  </Typography>
                                  <Box
                                    component="span"
                                    className={`${classes.underline} underline`}
                                  />
                                </Link>
                              </Box>
                            )
                          )}
                        </React.Fragment>
                      )}
                    {!developmentPageMenu &&
                      !homeTypePageMenu &&
                      !hideMenu &&
                      headerMenuType && (
                        <React.Fragment>
                          {data.prismic.allLayouts.edges[0].node.secondary_nav_b_2_b.map(
                            ({ title, link }, key) => (
                              <Box
                                key={key}
                                component="li"
                                px={2}
                                className={classes.navItem}
                              >
                                <Link
                                  className={classes.navLink}
                                  to={link ? linkResolver(link._meta) : ''}
                                >
                                  <Typography
                                    component="span"
                                    variant="subtitle1"
                                    color="textPrimary"
                                  >
                                    {title[0].text}
                                  </Typography>
                                  <Box
                                    component="span"
                                    className={`${classes.underline} underline`}
                                  />
                                </Link>
                              </Box>
                            )
                          )}
                        </React.Fragment>
                      )}

                    {developmentPageMenu && (
                      <React.Fragment>
                        <Box component="li" px={2} className={classes.navItem}>
                          <AnchorLink
                            to={`${linkResolver(
                              developmentPageMenu
                            )}#availability`}
                            title="Availability"
                            className={`${classes.navLink} stripped`}
                            stripHash
                          >
                            <Typography
                              component="span"
                              variant="subtitle1"
                              color="primary"
                            >
                              Availability
                            </Typography>
                            <Box
                              component="span"
                              className={`${classes.underline} underline`}
                            />
                          </AnchorLink>
                        </Box>
                        <Box component="li" px={2} className={classes.navItem}>
                          <AnchorLink
                            to={`${linkResolver(developmentPageMenu)}#siteplan`}
                            title="Availability"
                            className={`${classes.navLink} stripped`}
                            stripHash
                          >
                            <Typography
                              component="span"
                              variant="subtitle1"
                              color="primary"
                            >
                              Site Plan
                            </Typography>
                            <Box
                              component="span"
                              className={`${classes.underline} underline`}
                            />
                          </AnchorLink>
                        </Box>
                        <Box component="li" px={2} className={classes.navItem}>
                          <AnchorLink
                            to={`${linkResolver(
                              developmentPageMenu
                            )}#housetypes`}
                            title="Availability"
                            className={`${classes.navLink} stripped`}
                            stripHash
                          >
                            <Typography
                              component="span"
                              variant="subtitle1"
                              color="primary"
                            >
                              House Types
                            </Typography>
                            <Box
                              component="span"
                              className={`${classes.underline} underline`}
                            />
                          </AnchorLink>
                        </Box>
                      </React.Fragment>
                    )}
                    {homeTypePageMenu && (
                      <React.Fragment>
                        <Box component="li" px={2} className={classes.navItem}>
                          <AnchorLink
                            to={`${linkResolver(homeTypePageMenu)}#price`}
                            title="Price"
                            className={`${classes.navLink} stripped`}
                            stripHash
                          >
                            <Typography
                              component="span"
                              variant="subtitle1"
                              color="primary"
                            >
                              Price
                            </Typography>
                            <Box
                              component="span"
                              className={`${classes.underline} underline`}
                            />
                          </AnchorLink>
                        </Box>
                        <Box component="li" px={2} className={classes.navItem}>
                          <AnchorLink
                            to={`${linkResolver(homeTypePageMenu)}#floorplans`}
                            title="Floorplans"
                            className={`${classes.navLink} stripped`}
                            stripHash
                          >
                            <Typography
                              component="span"
                              variant="subtitle1"
                              color="primary"
                            >
                              Floorplans
                            </Typography>
                            <Box
                              component="span"
                              className={`${classes.underline} underline`}
                            />
                          </AnchorLink>
                        </Box>
                        <Box component="li" px={2} className={classes.navItem}>
                          <AnchorLink
                            to={`${linkResolver(homeTypePageMenu)}#downloads`}
                            title="Downloads"
                            className={`${classes.navLink} stripped`}
                            stripHash
                          >
                            <Typography
                              component="span"
                              variant="subtitle1"
                              color="primary"
                            >
                              Downloads
                            </Typography>
                            <Box
                              component="span"
                              className={`${classes.underline} underline`}
                            />
                          </AnchorLink>
                        </Box>
                      </React.Fragment>
                    )}
                  </Box>
                </Box>
              </Grid>
              <Box
                component={Grid}
                item
                xs={2}
                display="flex"
                alignItems="center"
                justifyContent="flex-end"
              >
                <Link to={'/'}>
                  <LogoGreen className={classes.ident} />
                </Link>
              </Box>
            </Grid>
          </Container>
        </Box>
      )}
    />
  );
}

Header.propTypes = {
  title: PropTypes.string.isRequired,
  themeColor: PropTypes.string,
  developmentPageMenu: PropTypes.object,
  homeTypePageMenu: PropTypes.object,
  hideMenu: PropTypes.bool,
  headerMenuType: PropTypes.bool,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Header);
